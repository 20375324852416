module.exports.routeNames = [
  { locale: 'cs', path: 'recenze' },
  { locale: 'pl', path: 'opinie' },
  {
    locale: 'uk',
    path: '%D0%B2%D1%96%D0%B4%D0%B3%D1%83%D0%BA%D0%B8',
  }, // відгуки
  { locale: 'hu', path: 'velemenyek' },
  { locale: 'sl', path: 'mnenji' },
  { locale: 'lt', path: 'apzvalgos' },
  { locale: 'ro', path: 'recenzii' },
  {
    locale: 'el',
    path: '%CE%BA%CF%81%CE%B9%CF%84%CE%B9%CE%BA%CE%AD%CF%82',
  }, // κριτικές
  { locale: 'sk', path: 'recenzie' },
]
